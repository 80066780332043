@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap');

body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/nomikaiwa-5a18a.appspot.com/o/images%2Fbackground.svg?alt=media&token=6a510b6a-e887-4ea1-a2e3-4c4928d13d48');
    background-repeat: no-repeat;
    background-size: cover;
}

.flashcard-header {
    font-size: 24px;
    font-weight: bold;
    padding: 15px 0;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.flashcard {
    max-width: 700px; /* Set a max-width that you find appropriate for desktop */
    min-width: 700px;
    width: 90%; /* This will make the card responsive and not exceed the max-width */
    min-height: 300px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 20px auto; /* Center the card horizontally */
    font-family: 'Yusei Magic', sans-serif;
}

.flashcard-japanese, .flashcard-english {
    flex: 1;
    display: flex;
    align-items: center; /* Vertically center */
    justify-content: center; /* Horizontally center */
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease;
    text-align: center; /* Center text for browsers that don't support flexbox */
}

.flashcard-japanese p, .flashcard-english p {
    width: 100%; /* Ensure the paragraph takes up the full width */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; /* Match the parent's height to center vertically */
    margin: 0;
    font-size: 24px;
}

.flashcard-japanese.active, .flashcard-english.active {
    visibility: visible;
    opacity: 1;
}

.language-toggle {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.language-toggle button {
    padding: 10px 15px;
    border: solid;
    border-color: #333333;
    border-radius: 5px;
    background-color: #ffffff;
    font-family: 'Yusei Magic', sans-serif;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.language-toggle button:hover {
    background-color: #777777;
    color: #ffffff;
}

.language-toggle button.active {
    background-color: #333333;
    color: #F2F2F2;
    pointer-events: none;
}

.next-button {
    padding: 10px 30px;
    border: none;
    border-radius: 5px;
    background-color: #66FCF1;
    color: #1F2833;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease;
    position: absolute;
    bottom: -45px;
    left: 50%;
    transform: translateX(-50%);
}

.next-button:hover {
    background-color: #58D4CB;
}

/* Responsive Styles */
@media (max-width: 600px) {
    .flashcard {
        width: 85%; /* Slightly more width on smaller screens */
        max-width: 375px;
        min-width: 375px;
        padding: 20px;
        min-height: 200px; /* Adjust the height as needed */
        margin: 20px auto; /* Ensure it stays centered */
    }

    .flashcard-japanese p, .flashcard-english p {
        font-size: 18px; /* Smaller font size on mobile */
    }
    
    .next-button {
        padding: 8px 25px; /* Smaller padding on mobile */
    }
}
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.main-content {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
}

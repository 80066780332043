@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.header {
    font-family: 'Inter', sans-serif; 
    font-size: 24px; 
    width: 100%;
    padding: 20px 0;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: fixed; 
    left: 0;
    top: 0; 
    z-index: 1000; 
}

/* Responsive header font size */
@media (max-width: 600px) {
    .header {
        font-size: 18px; 
        padding: 10px 0; 
    }
}

.footer {
    font-family: 'Yuji Syuku', sans-serif; /* Yuji Syuku font for the footer */
    font-size: 24px;
    width: 100%;
    padding: 15px 0;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
  }
  
  /* Responsive footer font size */
  @media (max-width: 600px) {
    .footer {
      font-size: 16px; 
      padding: 10px 0; 
    }
  }
  